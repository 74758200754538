/* Define the styles for the expanded state of the boxes */
.tech-stack-box.expanded {
    flex-direction: column;

  }
  
  /* Define the default width for the boxes */
  .tech-stack-box {
    width: 12rem;
    flex-direction: row;
  }
  
  .tech-stack-text.textexpanded{
    margin-Left: 0rem;
  }
  

  .tech-stack-text{
    margin-Left: 1rem;

  }
  
  /* Add a media query for screens with width above 500px */
  @media (max-width: 500px) {
    .tech-stack-box {
      width: 8rem;
      flex-direction: column;

    }
    .tech-stack-text{
      margin-Left: 0rem;
    }

  }




