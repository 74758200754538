.floatleft{
  float: left;
}

.a25, .a25text{
  width: 20%; /* 25 eller 33.33% */
  float: left;
}
.a25{
  width: 40%; /* 25 eller 33.33% */
  float: left;
}

.form-field-radio {
  position: relative;
  margin: 32px 0;
}



.center{
justify-content: center;
}
.requiredbutton, .requiredbutton:hover{
  text-decoration: none;
  color: #282828;
  font-weight: bold;
}






.bigwidth{
  width: 100%;
  position: relative;
}

.get-in-touch {
  max-width: 800px;
  margin: 50px auto 70px auto;
  position: relative;

}
.get-in-touch .title {
  text-align: center;
  letter-spacing: 3px;
  font-size: 3.2em;
  line-height: 48px;
  padding-bottom: 48px;
     color: #d43951;
    background: #d43951;
    background: -moz-linear-gradient(left,#d43951 0%,#960f23  100%) !important; /* #e0ce2a Alt gulfärg #dfdc30 */
    background: -webkit-linear-gradient(left,#d43951 0%,#960f23  100%) !important;
    background: linear-gradient(to right,#d43951 0%,#960f23  100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}


.contact-form .form-field {
  position: relative;
  margin: 32px 0; /* bottom margin */
}

.form-field-submit {
  position: relative;
  margin: 32px 0px 0px 0px;
}


.contact-form .input-text {
  display: block;
  width: 100%;
  height: 36px;
  border-width: 0 0 2px 0;
  border-color: #d43951;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  background-color: #f0f0f000;
  color: #ececec;

}


.contact-form .input-text:focus {
  outline: none;
}


.noresize-textarea {
  resize: none;
  overflow: hidden;
}

input:not(:placeholder-shown) + .label, /* placholder behövs på inputs för att det ska funka https://stackoverflow.com/questions/16952526/detect-if-an-input-has-text-in-it-using-css-on-a-page-i-am-visiting-and-do-no */
.contact-form .input-text:focus + .label,
.contact-form .input-text:not(:empty) + .label {
  top: 0px; /*Makes it so labels are top of textarea */
  -webkit-transform: translateY(-24px);
          transform: translateY(-24px);
}
.label,.radiolabel{
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #d43951;
  cursor: text;
}
.label{
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, 
  -webkit-transform .2s ease-in-out;
}


.contact-form .label{
  position: absolute;
  left: 20px;
  bottom: 11px;
}

.radiolabel{
  position: relative;
  left: 8px;
  bottom: 50%;
} 

.contact-form .submit-btn {
  display: inline-block;
  background-color: #000;
   background-image: linear-gradient(125deg, #d43951, #960f23);  /*Alt gulfärg #dfdc30 */
  color: #f0f0f0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  padding: 8px 16px;
  border: none;
  width: 200px;
  cursor: pointer;
}

.submit-btn{ /* Transiton out*/
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  transition: all 300ms linear;
  border-radius: 4px;
 

}
.submit-btn:hover { /* Transiton in*/
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transition: all 300ms linear;
}


@media screen and (max-width: 991px) { /* gör att knapparna centreras*/
  
  .contact-form{
    width: 80%;
    margin: auto;

  }
  
  /* Om man vill centrera Submit och recaptcha*/
  /*
  .mobile-contact-align-center{
      text-align: center;
  }
    /**/

}


.customlabel{
  top: 0px;
}


@media screen and (max-width: 991px) { /* gör att knapparna centreras*/
  .a25text{
    width: 33.33%;
    float: left;
  }
  .a25 {
    width: 33.33%;
    float: left;
    text-align: center; /* I LOVE YOU text-align: center;*/
  }
  .form-field-radio {
    position: relative;
    margin: 32px 0;
  }

}



@media screen and (max-width: 380px) { /* gör att knapparna centreras*/
  
  .recaptcha {
    width: 100%;
  
  border-radius: 4px;
  border-right: 1px solid #d8d8d8;
  overflow: hidden;
  }

  }
