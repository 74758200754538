/* 
className="hover-underline-animation"
hover-underline-animation

Sätt  className="hover-underline-animation"  under de texter som ska få animation
https://www.30secondsofcode.org/css/s/hover-underline-animation*/


/* Icons: 
https://freeicons.io/search/icons?q=contact%20&iuc=2205071709 
    https://icons8.com/
*/

@font-face { /* https://dev.to/ziqinyeow/how-to-add-custom-font-file-to-your-react-app-31kb */
  font-family: "custom_eras";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./conf/ERASBD.TTF") format("truetype");
}

::selection {
  background: rgb(189, 189, 189);
}

body {
  font-family: 'Roboto', sans-serif;
}

 .universalwhite{
  background-color: #f0f0f0;
}

.universalblack{
  color: #282828;

}
/* GULFÄRG #e0ce2a Alt gulfärg #dfdc30 */

.universalorange {
  color: #bb2037; /*alt #d48207 */
}

.hover-underline-animation { 
  display: inline-block;
  position: relative;
  color: #bb2037;
  text-decoration: none;

}

.hover-underline-animation:hover { 
  color: #bb2037;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #bb2037;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
