.expertiseboxes {
  width: 12rem;

}
  
  /* Add a media query for screens with width above 500px */
  @media (max-width: 480px) {
    .expertiseboxes {
      width: 8rem;

    }


  }




