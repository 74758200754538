body{
    background-color: #191321 ; /* #646364   #2e2c2c    */   
}

#homediv{
    height: 1000px;
}

.extraheigth{
    height: 1710px;
}
@media screen and (max-width: 950px) { /*orginal 769px*/
    .extraheigth{
        height: 1610px;
    }
}

.parallaxstyle{
    filter: grayscale(100%);
}




.background-svg-1{ 
	/*
    background-image: url('./slanted-gradient.svg');
    background-position: bottom;
    background-attachment: scroll;
    margin: 0;
    */

    margin: 0;

float: right;
position: relative;
    height: auto;
	width: 100%;
	background-image: url('./g-black.svg'), url('./g.svg');
    
    background-attachment: scroll;    
    background-position: top;
    background-size: contain;
 background-repeat: no-repeat, repeat; /**/



    background-color: #f1cbce;
z-index: 3;
    /* 
    1a1c2a
191321
    */
}

@media screen and (max-width: 567px) {
    /*.background-svg-1{ 
        padding-bottom: 335px;
    } */
}


.background-svg-2{ 
	/*
    background-image: url('./slanted-gradient.svg');
    background-position: bottom;
    background-attachment: scroll;
    margin: 0;
    */
    z-index: 1;

    margin: 0;
    height: auto;
	width: 100%;
	background-image: url('./g.svg');
    
    background-attachment: scroll;    
    background-position: top;
    background-size: contain;
 /*background-repeat: no-repeat; /**/



    background-color: #f1cbce;


    /* 
    1a1c2a
191321
    */

}