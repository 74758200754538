body {
 

  /*
  margin: 0;
	height: 100%;
	width: 100vw;
	background-color: #c9c6bc;
	background-image: url('./pages/g.svg');
    
	background-attachment: fixed;
	background-size: cover;
	background-position-y: -15vh;

  */

  }

.testdiv{
	width: 100%;
    overflow: hidden;

}

  .appdiv{
	position: relative;
  }