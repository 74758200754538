
/* OBS!  hover-underline-animation finns i css filen universal.css i src mappen */

.all-footer-content-grid{

z-index: 50;
bottom: 0;
background-color: #00000033;

    width: 100%;
    display: grid;
    overflow: hidden;
    grid-template-columns: auto; /* Grid funktionen gör så att alla footer blir lika långa https://www.w3schools.com/cssref/pr_grid-template-columns.asp */
    padding: 0px;
    
}

.all-footer-content{
    width: 100%;
    float: left;

 
}
.footer-border{
     border-top: 3px solid #960f23; /* 960f23*/
    /*background-image: linear-gradient(298deg, #960f23, #d43951);*/


}




.footer-sides{
    width: 14%;
    float: left;

}



.footer-content{
    width: 24%;
    float: left;
    height: 100%;
    position: relative;
}





.svg-footer-style{
    height: 36px; 
    width: 36px;
    margin-right: 6px;
    /* background-color: rgb(51, 50, 52); /* https://codepen.io/sosuke/pen/Pjoqqp */
    filter: invert(100%) sepia(7%) saturate(990%) hue-rotate(277deg) brightness(118%) contrast(88%);
}

.svg-footer-style:hover{
    filter: invert(17%) sepia(42%) saturate(6431%) hue-rotate(340deg) brightness(88%) contrast(89%);
    transition: 0.3s ease all;
}

.svg-footer-style-email{
    height: 42px; 
    width: 42px;
    margin-right: 6px;

}

.footer_links{
    /* color: black; */
    text-decoration: none;
}




.contact-row{
    margin-top: 12px;
    margin-bottom: 12px;
}
/*.contact-row-lin{
    margin-top: 10px;
    margin-bottom: 5px;
} /**/

.footer-contact{
    width: 130px;
    display: inline-block;
    /*background-color: aqua; /**/
    margin: auto;
}
@media screen and (min-width: 568px) {
    
    .phone-footer-contact-info{
        display: none;}

    .all-footer-content-grid{
        height: 270px;
    }
}


    



.logo-footer-style{

    width: 50%; 
    max-width: 200px;
    min-width: 200px;
    /* background-color: rgb(51, 50, 52); /* https://codepen.io/sosuke/pen/Pjoqqp */
    /* filter: invert(19%) sepia(88%) saturate(1940%) hue-rotate(330deg) brightness(90%) contrast(83%); */
    filter: invert(10%) sepia(90%) saturate(3707%) hue-rotate(341deg) brightness(93%) contrast(98%);

}

.helped-companies{
    /* LATER VERSION
    margin-top: 30px;
    margin-bottom: 20px; */

    padding-top: 20px;
    margin-bottom: 30px;


}

.helped-companies-text{
    margin-bottom: 5px;
    color: rgb(223, 223, 223);
}

.helped-svg-footer-style{
    height: 24px; 
    width: 24px;
    margin: 2px;
    filter: invert(100%) sepia(7%) saturate(474%) hue-rotate(269deg) brightness(115%) contrast(100%);    
    /* background-color: rgb(51, 50, 52); /* https://codepen.io/sosuke/pen/Pjoqqp */
}

.only-contact-footer{
    padding-bottom: 50px; /*Later version */
}



.madeby_link{
    text-decoration: none;
    /*color: black; */
    bottom: 0;

    font-size: 10px;

    position: absolute;

    left: 0; 
    right: 0; 




}
/* .madeby_link:hover{color: black;} */

.center-div {
    position: relative;
    top: 25%;
}

@media screen and (max-width: 992px) {
    .footer-sides{
        display: none;
    }
    .footer-content{
        width: 50%;
    }
    
    .mid-content{ /* .class1 .class2 (kollar efter element med båda elementen) */
        width: 34%;
        display: none;
        float: left;
    }

}



/* 
@media screen and (min-width: 992px) and (max-width: 992px) {
    .ipadphone-gone{
        display: none;
    }
  

} 
*/