.provide-allcontent {
    padding-bottom: 30px;
    padding-top: 110px;

  }
  
  .provide-allcontent2 {
    max-width: 80%;
    margin: 0 auto;
    /*padding: 0 16px;
    padding: 0 24px;
    padding: 0 32px;
    padding-top: 20px; */
  }
  
  .provide-title {
    font-size: 48px;
    font-weight: bold;
    margin: 0;
    padding: 0 16px;
    text-align: center;
    color: white;
  }
  

  
  
  .provide-eachcontent-all {
    margin-top: 8px;
  } 
  
  /*.provide-eachcontent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
  } */

  
  
    /*.provide-allcontent2 {
      padding: 0 24px;
    } 
  
    .provide-title {
      padding: 0 24px;
    }*/
  
 
  
    .provide-eachcontent {
      width: 33%;

      padding: 10px 40px 10px 40px;  /* top right bottom left */


      background-color: #e2e8f000;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 8px;
      margin-right: 15px;
      margin-left: 15px; /*SAME */
      color:#d43951;
      border: 2px solid #d43951;
    }

    .provide-title2 {
        margin-top: 16px;
        font-size: 24px;
        font-weight: 600;
        color: #ffffff; /* #d43951 #ffffff */
        margin-left: 15px; /*SAME */
      }

    .provide-eachcontent-img {
        margin-top: 20px;
        width: 80px;
        filter: invert(29%) sepia(62%) saturate(3753%) hue-rotate(334deg) brightness(90%) contrast(82%);
        /*filter: invert(98%) sepia(0%) saturate(2%) hue-rotate(266deg) brightness(110%) contrast(101%);
        /* #d43951
        https://codepen.io/sosuke/pen/Pjoqqp
        filter: invert(29%) sepia(62%) saturate(3753%) hue-rotate(334deg) brightness(90%) contrast(82%);
        filter: invert(98%) sepia(0%) saturate(2%) hue-rotate(266deg) brightness(110%) contrast(101%);
        */
      }
      
      
      .provide-eachcontent-title {
        font-size: 20px;
        font-weight: bold;
        margin-top: 4px;
      }
      
      .provide-eachcontent-description {
        font-size: 18px;
        margin-top: 2px;
        text-align: justify;
      }




@media (max-width: 1410px) {
    .provide-allcontent2 {
        max-width: 96%;
        margin: 0 auto;
        /*padding: 0 16px;
        padding: 0 24px;
        padding: 0 32px;
        padding-top: 20px; */
    }

 }

 @media (max-width: 1250px) {
 .provide-eachcontent {
    padding: 10px 25px 10px 25px;  /* top right bottom left */
    margin-right: 10px;
    margin-left: 10px; /*SAME */
  }
  .provide-title2 {margin-left: 10px; }/*SAME */

}


@media (max-width: 1000px) {
    .provide-eachcontent {
       padding: 10px 10px 10px 10px;  /* top right bottom left */
       margin-right: 7px;
       margin-left: 7px; /*SAME */
     }
     .provide-title2 {margin-left: 7px; }/*SAME */

     .provide-allcontent2 {
        max-width: 99%;
      }

}



@media (min-width: 751px) {
.provide-eachcontent-all{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  } 
} 

@media (max-width: 750px) {
    .provide-allcontent {
        padding-top: 70px;
    
      }
        .provide-allcontent2 {
            max-width: 94%;
            margin: 0 auto;
            /*padding: 0 16px;
            padding: 0 24px;
            padding: 0 32px;
            padding-top: 20px; */
          }

        .provide-eachcontent {
            width: 100%;
      
            padding: 10px 30px 10px 30px;  /* top right bottom left */
      
      
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 8px;
            margin-bottom: 17px;
            margin-right: 0px;
            margin-left: 0px; /*SAME */
          }
    .provide-title2 {margin-left: 0px; }/*SAME */

}



  

  