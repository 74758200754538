body {
  /* font-family: "Montserrat", sans-serif; */
  font-size: 16px;
  color: rgb(53, 53, 53);
}


.ant-switch{
  background-color: #7a7a7a !important;
}
.ant-switch-checked {
  background-color: #4d4d4d !important;
}



.timelineimagediv{
  width: 100%;
  text-align: center;
}

.timelineimage{
  max-width:100%;
  max-height:100%;
  vertical-align: middle; /*Dessa 3 rader gör så bilden passar */
  text-align: center;

  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important; /*Skugga runt bild */
}


.timeline-overhead{
 width: 100%;
 position: relative;
 height: 150px;
 float: left;
}

.timeline-overhead-left, .timeline-overhead-center, .timeline-overhead-right{
  width: 30%;
  height: 150px;
  position: relative;
  float: left;
  padding-top: 50px;
z-index: 100;


}

.timeline-overhead-center{
  width: 40%;

}




.timelinetitle {
  font-size: 4.5em; 
  text-align: center;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: bold;
  color: rgb(236, 236, 236);

}
.timelineformobile{ display: none; }

@media only screen and (max-width: 500px) {
  .timelineformobile{ display: block; }
  .timelinefordesktop{ display: none; }
}



.filtertimeline{
  margin-bottom: 100px;
  position: relative;
  display: flex;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}



.timeline-content{
  padding-top: 180px;
  z-index: 1;
}

h3 {
  padding-top: 0.25em;
  font-weight: bold;
  font-size: 1.17em
}

.highlighted{
  display: inline;
  float: right;
  width: 20px;
  fill: #f3bc3c;
}

h5 {
  font-weight: bold;
  font-size: 0.83em
}



.content_shadow .vertical-timeline-element-content { /* Detta gör så att endast innehåll har bakgrundsskugga, inte mer knappen */
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  overflow-wrap: break-word;
  padding: 2em 2.5em !important;
  /* 
  /**/
}

@media only screen and (max-width: 576px) {
  .content_shadow .vertical-timeline-element-content { /* Detta gör så att endast innehåll har bakgrundsskugga, inte mer knappen */
    padding: 1.6em 1.6em !important;
  }

}

/* todo
@media only screen and (max-width: 400px) {
  .readmorebutton {
    padding: 0.4em 0.9em;
    border-radius: 3px;
  }
} */




.switch-style{
  display: inline;
  transform: scale(1.5); /* */
  margin-right: 30px;
  margin-top: 10px;
  margin-left: 15px;
  
}


.date {
  color: rgb(255, 255, 255); /* .vertical-timeline-element-date */

}

#description {
  margin: 1.5em 0 0em 0;
}


#lessons{
  padding-bottom: 20px;
  font-weight: bold;
}
.actuallessons{
  font-style: italic;
  /*font-size: 16px; */
}

.readmorebutton {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white; /* Knapptext */
  float: left;
}



.readmorebutton:hover {
  color: white; /* Knapptext */
}

.workButton {
  background-color: #963cb1; /* Länk till projekt, basfärg*/}
.workButton:hover {
  background-color: #7c3392; /* Länk till projekt, hover färg*/}

.schoolButton {
  background-color: #0bb99c; /* Länk till projekt, basfärg*/}
.schoolButton:hover {
  background-color: #0ea88f; /* Länk till projekt, hover färg*/}

.newworkButton {
  background-color: #42a73f; /* Länk till projekt, basfärg*/}
.newworkButton:hover {
  background-color: #388d35; /* Länk till projekt, hover färg*/}

/*.hobbyButton {
  background-color: #1b06d6; } /* Länk till projekt, basfärg*/
/*.hobbyButton:hover {
  background-color: #180a96; } /* Länk till projekt, hover färg*/




  /*.showMoreTimelineButton {
    background-color: #f116c2;} /* Länk till projekt, basfärg*/
/*.showMoreTimelineButton:hover {
    background-color: #4e093f;} /* Länk till projekt, hover färg*/

    .vertical-timeline-element-icon.vertical-timeline-element-icon--button { /*Tar bort vita ramen runt knappen*/
      box-shadow: none;}


    .vertical-timeline-element-icon.vertical-timeline-element-icon--button svg {
      display: inline-block;
      width: 90%;
      height: 90%;
      position: absolute;
      left: auto;
      top: auto;
      margin-left: auto;
      margin-top: auto;
    }
    .vertical-timeline-element-icon.vertical-timeline-element-icon--button .fab-button {
      width: 40px;
      height: 40px;
      background-color: #942525;
    }


.fab-button:hover {
  cursor: pointer;

    }


    
    @media only screen and (max-width: 1169px) {
      .timeline-overhead-left{
        display: none;
      }
      
      .timeline-overhead-center, .timeline-overhead-right{
        width: 100%;
        height: 150px;
        position: relative;
        float: left;
        padding-top: 50px;
      
      }
      


      .timeline-content{
        padding-top: 220px; /*350 */
        z-index: 1;
      }

      .filtertimeline{
        margin-bottom: 100px;
        position: absolute;
        display: flex;
        top: 0%;
        left: 50%;
        -ms-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%);
      }

      .vertical-timeline-element-date {
        display: block !important;
        float: right !important;
        color: rgb(44, 44, 44);
        
      }

    }
  
    
    @media only screen and (min-width: 1170px) {
      .vertical-timeline-element-icon.vertical-timeline-element-icon--button .fab-button {
        width: 60px;
        height: 60px;
      }
      .vertical-timeline-element-icon.vertical-timeline-element-icon--button svg {
        display: inline-block;
        width: 80%;
        height: 80%;
      }

    }


.vertical-timeline.vertical-timeline-custom-line-highlighted--true::before { background: #ffff08;} /*Disable line to remove color from timeline line  */
.vertical-timeline.vertical-timeline-custom-line-highlighted--false::before { background: white;} /*Disable line to remove color from timeline line  */


/* Icon container's border color and border shadow, disable lines below to make normal */
  .vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--work .vertical-timeline-element-icon, /*{box-shadow: 0 0 0 4px #f3bc3c, inset 0 2px 0 rgba(155, 17, 17, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);} */
  .vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--education .vertical-timeline-element-icon, /*{box-shadow: 0 0 0 4px #f3bc3c, inset 0 2px 0 rgba(155, 17, 17, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);} */
  .vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--hobbies .vertical-timeline-element-icon
  { box-shadow: 0 0 0 4px #ffff08, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);}
  /* */



