/*
* {
    user-select: none;
  }
  
  *:focus {
    outline: none;
  }
  
*/

:root {
	--option1: #942525;  /* */
	--option2: #963cb1;
	--option3: #0bb99c;
	--option4: #42a73f;
}
  

  
  .brd {
    border: 1px solid #e2eded;
    border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
  }
  

  
  #app-cover {
    position: relative;
    width: 200px;
    height: 42px;
    z-index: 100;
  }
  
  #select-button {
    position: relative;
    height: 100%;
    padding: 12px 14px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
  
  #options-view-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  
  #selected-value {
    font-size: 16px;
    line-height: 1;
    /*margin-right: 26px;*/
  }
  
  .option img {
    width: 16px;
    height: 16px;
  }
  
  .option,
  .label-select {
    color: #2d3667;
    font-size: 16px;
  }
  
  #chevrons {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 12px;
    padding: 9px 14px;
  }
  
  #chevrons img {
    display: block;
    height: 50%;
    color: #d1dede;
    font-size: 12px;
    text-align: right;
  }
  
  #options-view-button:checked + #select-button #chevrons img {
    color: #2d3667;
  }
  
  .options {
    position: absolute;
    left: 0;
    width: 150px;
  }
  
  #options {
    position: absolute;
    top: 42px;
    right: 0;
    left: 0;
    width: 200px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 4px;
  }
  
  #options-view-button:checked ~ #options {
    border-top: 1px solid #e2eded;
    border-bottom: 1px solid #e2eded;
    border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
  }
  
  .option {
    position: relative;
    line-height: 1;
    transition: 0.3s ease all;
    z-index: 2;
  }


  
  .option img {
    position: absolute;
    left: 14px;
    padding: 0;
    display: none;
  }
  
  #options-view-button:checked ~ #options .option img {
    display: block;
    margin-top: 10px;
  }
  
  .label-select {
    display: none;
    padding: 0;
    margin-left: 27px;
  }
  
  #options-view-button:checked ~ #options .label-select {
    display: block;
    padding: 12px 14px;
  }
  
  .s-c {
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
  }
  
  .s-c.top {
    top: 0;
  }
  
  .s-c.bottom {
    bottom: 0;
  }
  
  .radioinput-select {
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    height: 50%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  .s-c:hover ~ img {
    filter: invert(100%) sepia(96%) saturate(0%) hue-rotate(75deg) brightness(103%) contrast(108%);
    transition: 0.3s ease all;

}
  
  .s-c:hover {
    height: 100%;
    z-index: 1;
  }

  
  /* img transition easin*/
  /*
  .s-c.bottom:hover + img {
    bottom: -25px;
    animation: moveup 0.3s ease 0.1s forwards;
  }
  
  .s-c.top:hover ~ img {
    top: -25px;
    animation: movedown 0.3s ease 0.1s forwards;
  }
  */
  
  @keyframes moveup {
    0% {
      bottom: -25px;
      opacity: 0;
    }
    100% {
      bottom: 0;
      opacity: 1;
    }
  }
  
  @keyframes movedown {
    0% {
      top: -25px;
      opacity: 0;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }
  
  .label-select {
    transition: 0.3s ease all;
  }
  
  .opt-val {
    position: absolute;
    left: 14px;

    width: 150px;
    height: 21px;
    opacity: 0;
    background-color: #fff;
    transform: scale(0);
  }
  
  .option .radioinput-select:checked ~ .opt-val {
    opacity: 1;
    transform: scale(1);
  }
  
  .option .radioinput-select:checked ~ img {
    top: 0;
    bottom: auto;
    opacity: 1;
    animation: unset;
    filter: invert(100%) sepia(96%) saturate(0%) hue-rotate(75deg) brightness(103%) contrast(108%);

  }
  
  .option .radioinput-select:checked ~ img,
  .option .radioinput-select:checked ~ .label-select {
    color: #fff;
  }
  
  .option .radioinput-select:checked ~ .label-select:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  
  #options-view-button:not(:checked)
    ~ #options
    .option
    .radioinput-select:checked
    ~ .opt-val {
    top: -30px;
  }
  
  .option:nth-child(1) .radioinput-select:checked ~ .label-select:before {
    background-color: var(--option1);
    border-radius: 4px 4px 0 0;
  }
  
  .option:nth-child(1) .radioinput-select:checked ~ .opt-val {
    top: -31px;
  }
  
  .option:nth-child(2) .radioinput-select:checked ~ .label-select:before {
    background-color: var(--option2);
  }
  
  .option:nth-child(2) .radioinput-select:checked ~ .opt-val {
    top: -71px;
  }
  
  .option:nth-child(3) .radioinput-select:checked ~ .label-select:before {
    background-color: var(--option3);
  }
  
  .option:nth-child(3) .radioinput-select:checked ~ .opt-val {
    top: -111px;
  }
  
  .option:nth-child(4) .radioinput-select:checked ~ .label-select:before {
    background-color: var(--option4);
  }
  
  .option:nth-child(4) .radioinput-select:checked ~ .opt-val {
    top: -151px;
  }
  
  .option:nth-child(5) .radioinput-select:checked ~ .label-select:before {
    background-color: #f48024;
  }
  
  .option:nth-child(5) .radioinput-select:checked ~ .opt-val {
    top: -191px;
  }
  
  .option:nth-child(6) .radioinput-select:checked ~ .label-select:before {
    background-color: #006400;
    border-radius: 0 0 4px 4px;
  }
  
  .option:nth-child(6) .radioinput-select:checked ~ .opt-val {
    top: -231px;
  }
  
  .option .fa-codepen {
    color: var(--option1);
  }
  
  .option .fa-dribbble {
    color: var(--option2);
  }
  
  .option .fa-behance {
    color: var(--option3);
  }
  
  .option .fa-hackerrank {
    color: var(--option4);
  }
  
  .option .fa-stack-overflow {
    color: #f48024;
  }
  
  .option .fa-free-code-camp {
    color: #006400;
  }
  
  #option-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 40px;
    transition: 0.3s ease all;
    z-index: 1;
    display: none;
  }
  
  #options-view-button:checked ~ #options #option-bg {
    display: block;
  }
  
  .option:hover .label-select {
    color: #fff;
  }
  
  .option:nth-child(1):hover ~ #option-bg {
    top: 0;
    background-color: var(--option1);
    border-radius: 4px 4px 0 0;
  }
  
  .option:nth-child(2):hover ~ #option-bg {
    top: 40px;
    background-color: var(--option2);
  }
  
  .option:nth-child(3):hover ~ #option-bg {
    top: 80px;
    background-color: var(--option3);
  }
  
  .option:nth-child(4):hover ~ #option-bg {
    top: 120px;
    background-color: var(--option4);
    border-radius: 0 0 4px 4px;

  }
  
  .option:nth-child(5):hover ~ #option-bg {
    top: 160px;
    background-color: #f48024;
  }
  
  .option:nth-child(6):hover ~ #option-bg {
    top: 200px;
    background-color: #006400;
    border-radius: 0 0 4px 4px;
  }
  