.navbarstyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #191321;
  height: 80px;
  padding: 20px 40px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  margin-top: -80px;/* så att navbar ligger över bakgrundsbilden */


  /* Dessa 3 rader fäster navbar högst upp på skärmen när man skrollar ner, vänta tills hemsidan är större  */
  
  position: sticky; 
  top: 0;
  z-index: 2001; /* Later version */
}

.navbarstylecolor1 {
  background: #191321;

}

.navbarstylecolor2 {
  background-color: #000000d5;
  border-bottom: 3px solid #960f23; /* 960f23*/

}



.navbaridthing {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner1{
  height: 43px;
  margin-top: -10px;
  float: left;

  filter: invert(19%) sepia(88%) saturate(1940%) hue-rotate(330deg) brightness(90%) contrast(83%); 
  /*
  filter: invert(10%) sepia(90%) saturate(3707%) hue-rotate(341deg) brightness(93%) contrast(98%);
  */
}

.banner2{
  height: 43px;
  margin-top: -7px;
  float: left;
  filter: invert(10%) sepia(90%) saturate(3707%) hue-rotate(341deg) brightness(93%) contrast(98%);

}



.navbaridthing li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}

.navbaridthing li a {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  color: #f0f0f0;
  transition: 0.3s ease-in-out;
}
.navbaridthing li a:hover,
.navbaridthing li a.active,
.navbaridthing .active {
  cursor: pointer;
  color: #d43951;
}

/*.navbaridthing li a:hover::after,
.navbaridthing li a.active::after,
.navbaridthing .active {
  content: "";
  width: 30%;
  height: 2px;
  background: #d43951;
  position: absolute;
  bottom: -4px;
  left: 20px;
} */
.nav-item {
  color: #f0f0f0;


}

.nav-item .active {
  color: #d43951;
  padding-bottom: 6px;
  border-bottom: 3px solid #d43951;

}

#mobile {
  display: none;
}

#mobile i {
  color: #f0f0f0;
  align-items: center;
}



@media screen and (max-width: 900px) { /*orginal 769px*/
  .navbaridthing {
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    top: 79px;
    right: -300px;
    
    width: 300px;
    overflow: hidden;
    height: 100vh;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
    z-index: 2002;

    /*background: #373737; /* LATER VERSION, alternativ färg #282828 */
    position: fixed;
  }

  #navbarid1{
    background: linear-gradient(#20192b, #20192b00); /* Ta bort efter ny verision*/

  }

  #navbarid2{
    background: linear-gradient(#000000b2, #00000000); /* Ta bort efter ny verision*/

  }

  .navbaridthing.active {
    right: 0px;
    display: flex;


    
  }

  .navbaridthing li {
    margin-bottom: 25px;
  }

  #mobile {
    display: block;
  }

  #mobile i {
    font-size: 24px;
    cursor: pointer;

  }
}



@media screen and (max-width: 550px) { /*orginal 769px*/

  .navbaridthing {
    right: -200px;
    
    width: 200px;
    
  }
}

