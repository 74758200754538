

.for-checkbox-tools,
.for-checkbox-tools-custom{
  display:inline;
  overflow: auto;
  color: #282828;

}

/* Please ❤ this if you like it! */

.floatright{
	float:right;
}





@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=devanagari,latin-ext');


:root {
	--white: #ffffff;  /* */
	--light: #f0eff3;
	--black: #000000;
	--dark-blue: #1f2029;
	--dark-light: #1a3ada; /*För toolbox */
	--red: #960f23;
	--color2: #d43951;
	--universalwhite: #f0f0f0;
	--universalblack: #d43951;
	--forradiobuttons: #0a0909;

}

/* #Primary
================================================== */
/*
body{
	width: 100%;
	background: var(--dark-blue);
	overflow-x: hidden;
    font-family: 'Poppins', sans-serif;
	font-size: 17px;
	line-height: 30px;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
p{
    font-family: 'Poppins', sans-serif;
	font-size: 17px;
	line-height: 30px;
	color: var(--white);
	letter-spacing: 1px;
	font-weight: 500;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
} */

/*
::selection {
	color: var(--white);
	background-color: var(--black);
}
::-moz-selection {
	color: var(--white);
	background-color: var(--black);
}
*/

mark{
	color: var(--white);
	background-color: var(--black);
}
.section {
    position: relative;
	width: 100%;
	display: block;
	text-align: center;
	margin: 0 auto;
}
.over-hide {
    overflow: hidden;
}
.z-bigger {
    z-index: 100 !important;
}


.background-color{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--dark-blue);
	z-index: 1;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
.checkbox:checked ~ .background-color{
	background-color: var(--white);
}

.checkboxtypecontact:checked,
.checkboxtypecontact:not(:checked),
.radiotypecontact:checked,
.radiotypecontact:not(:checked){
	position: absolute;
	left: -9999px;
	width: 0;
	height: 0;
	visibility: hidden;
}

.checkbox:checked + label,
.checkbox:not(:checked) + label{
	position: relative;
	width: 70px;
	display: inline-block;
	padding: 0;
	margin: 0 auto;
	text-align: center;
	margin: 17px 0;
	margin-top: 100px;
	height: 6px;
	border-radius: 4px;
	background-image: linear-gradient(298deg, var(--red), var(--color2));
	z-index: 100 !important;
}
.checkbox:checked + label:before,
.checkbox:not(:checked) + label:before {
	position: absolute;
	/* font-family: 'unicons'; */
	cursor: pointer;
	top: -17px;
	z-index: 2;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}

.checkbox:not(:checked) + label:before {
	content: '\eac1';
	left: 0;
	color: var(--grey);
	background-color: var(--dark-light);
	box-shadow: 0 4px 4px rgba(0,0,0,0.15), 0 0 0 1px rgba(26,53,71,0.07);
}

.checkbox:checked + label:before {
	content: '\eb8f';
	left: 30px;
	color: var(--color2);
	background-color: var(--dark-blue);
	box-shadow: 0 4px 4px rgba(26,53,71,0.25), 0 0 0 1px rgba(26,53,71,0.07);
}

.checkbox:checked ~ .section .container .row .col-12 p{
	color: var(--dark-blue);
}

.svg-style-contact{ /* Sätt <br/> efter img och texten för att ta ny rad */
	height: 53px; 
	width: 36px;
	filter: invert(23%) sepia(43%) saturate(5223%) hue-rotate(337deg) brightness(94%) contrast(75%);	
	display:inline;
	margin-right: 7px;
	margin-top: -3px;
	/* For filter (for color svg ) */
	/* background-color: rgb(51, 50, 52); /* https://codepen.io/sosuke/pen/Pjoqqp */
  }



/* FÖR RADIO BUTTONS 
För textfärg/fonts i radio buttons OCH border*/
.checkbox-tools:checked + label,
.checkbox-tools:not(:checked) + label, 
.checkbox-tools-custom:checked + label,
.checkbox-tools-custom:not(:checked) + label{
	position: relative;
	display: inline-block;
	padding: 2px;
	width: 210px;
	font-size: 14px;
	line-height: 20px;
	/*letter-spacing: 1px; */
	margin: 0 auto;
	margin-left: auto;
	margin-right: auto;
	/*margin-bottom: 10px;*/
	text-align: center;
	border-radius: 1em;
	overflow: hidden;
	cursor: pointer;
	/*text-transform: uppercase;*/
	color: var(--universalblack);
	-webkit-transition: all 00ms linear;
	transition: all 00ms linear; 


	/*För border https://www.youtube.com/watch?v=k-kyiUuRxkQ&ab_channel=LeanneR + https://developer.mozilla.org/en-US/docs/Web/CSS/background-clip*/
	border: 5px solid var(--color2);
	border-radius: 1em;
	border: double 5px transparent;
	background-image: linear-gradient(#ffffff00, #ffffff00),
	linear-gradient(125deg, var(--color2), var(--red));
	background-origin: border-box;
	background-clip: padding-box, border-box; 

}

@media screen and (max-width: 991px)  and (min-width: 536px) {
	.checkbox-tools:checked + label,.checkbox-tools:not(:checked) + label{
		padding-left: 5px;
		padding-right: 5px;
		width: 155px;
		max-width: 90%;
	}

	.svg-style-contact{
		margin-right: 5px;
		margin-top: -3px;
		/* For filter (for color svg ) */
		/* background-color: rgb(51, 50, 52); /* https://codepen.io/sosuke/pen/Pjoqqp */
	  }

}
@media screen and (max-width: 535px) { 
	.svg-style-contact{ /* Sätt <br/> efter img och texten för att ta ny rad */
		margin-right: 0px;
		margin-top: 0px;
	}
}
@media screen and (max-width: 535px) and (min-width: 385px) { 
	.checkbox-tools:checked + label,.checkbox-tools:not(:checked) + label{
		width: 82px;
	}
  }

@media screen and (max-width: 384px) { 
	.checkbox-tools:checked + label,.checkbox-tools:not(:checked) + label{
		width: 78px;
		margin-left: 3px;
		margin-right: 3px;
	}
  }

/*CSS FOR CHECKED RADIO (WHITE SVG+TEXT*/
.checkbox-tools:checked + label,
.checkbox-tools-custom:checked + label{
	color: var(--universalwhite);
}
.checkbox-tools:checked + label img,
.checkbox-tools-custom:checked + label i{
	-webkit-transition: all 0ms linear;
	transition: all 0ms linear; 
	filter: invert(100%) sepia(7%) saturate(990%) hue-rotate(277deg) brightness(118%) contrast(88%);}





/* Radio buttons background*/
.checkbox-tools:not(:checked) + label,
.checkbox-tools-custom:not(:checked) + label{
	/*För border https://www.youtube.com/watch?v=k-kyiUuRxkQ&ab_channel=LeanneR + https://developer.mozilla.org/en-US/docs/Web/CSS/background-clip*/
	border: 5px solid var(--color2);
	border-radius: 1em;
	border: double 5px transparent;
	background-image: linear-gradient(var(--forradiobuttons), var(--forradiobuttons)),
	linear-gradient(125deg, var(--color2), var(--red));
	background-origin: border-box;
	background-clip: padding-box, border-box; 


	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.checkbox-tools-custom:checked + label,
.checkbox-tools-custom:not(:checked) + label{
	width: 70px;
}
.customcheckbox{
	top: -6px;
	width: 50px;
}

.displaynone{
	display: none;
}
@media screen and (max-width: 550px) { 
	.displaynone{
		display: block;
	}
	.customcheckbox{
		top: -18px;
	}
  }


.checkbox-tools:checked + label{
	background-color: transparent;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.checkbox-tools:not(:checked) + label:hover{
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.checkbox-tools:checked + label::before,
.checkbox-tools:not(:checked) + label::before{
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	background-image: linear-gradient(298deg, var(--red), var(--color2));
	z-index: -1;
}
.checkbox-tools:checked + label .uil,
.checkbox-tools:not(:checked) + label .uil{
	font-size: 24px;
	line-height: 24px;
	display: block;
	padding-bottom: 10px;
}

.checkbox:checked ~ .section .container .row .col-12 .checkbox-tools:not(:checked) + label{
	background-color: var(--light);
	color: var(--dark-blue);
	box-shadow: 0 1x 4px 0 rgba(0, 0, 0, 0.05);
}

.checkbox-budget:checked + label,
.checkbox-budget:not(:checked) + label{
	position: relative;
	display: inline-block;
	padding: 0;
	padding-top: 20px;
	padding-bottom: 20px;
	width: 260px;
	font-size: 52px;
	line-height: 52px;
	font-weight: 700;
	letter-spacing: 1px;
	margin: 0 auto;
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom: 10px;
	text-align: center;
	border-radius: 4px;
	overflow: hidden;
	cursor: pointer;
	text-transform: uppercase;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
	-webkit-text-stroke: 1px var(--white);
    text-stroke: 1px var(--white);
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    color: transparent;
}
.checkbox-budget:not(:checked) + label{
	background-color: var(--dark-light);
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.checkbox-budget:checked + label{
	background-color: transparent;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.checkbox-budget:not(:checked) + label:hover{
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.checkbox-budget:checked + label::before,
.checkbox-budget:not(:checked) + label::before{
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	background-image: linear-gradient(138deg, var(--red), var(--color2));
	z-index: -1;
}
.checkbox-budget:checked + label span,
.checkbox-budget:not(:checked) + label span{
	position: relative;
	display: block;
}
.checkbox-budget:checked + label span::before,
.checkbox-budget:not(:checked) + label span::before{
	position: absolute;
	content: attr(data-hover);
	top: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
	-webkit-text-stroke: transparent;
    text-stroke: transparent;
    -webkit-text-fill-color: var(--white);
    text-fill-color: var(--white);
    color: var(--white);
	-webkit-transition: max-height 0.3s;
	-moz-transition: max-height 0.3s;
	transition: max-height 0.3s;
}
.checkbox-budget:not(:checked) + label span::before{
	max-height: 0;
}
.checkbox-budget:checked + label span::before{
	max-height: 100%;
}

.checkbox:checked ~ .section .container .row .col-xl-10 .checkbox-budget:not(:checked) + label{
	background-color: var(--light);
	-webkit-text-stroke: 1px var(--dark-blue);
    text-stroke: 1px var(--dark-blue);
	box-shadow: 0 1x 4px 0 rgba(0, 0, 0, 0.05);
}


/* CHECKBOX Checked*/ 
.checkbox-booking:checked + label,
.checkbox-booking:not(:checked) + label{
	position: relative;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	-ms-flex-pack: center;
	text-align: center;
	padding: 0;
	/*padding: 6px 25px;*/
	width: 66px;
	height: 40px;
	font-size: 14px;
	line-height: 30px;
	letter-spacing: 1px;
	margin: 0 auto;
	margin-left: 6px;
	margin-right: 6px;
	margin-bottom: 16px;
	text-align: center;
	border-radius: 4px;
	cursor: pointer;
    color: var(--white);
	text-transform: uppercase;
	background-color: var(--dark-light);
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 

}

/* CHECKBOX NOT Checked*/ 
.checkbox-booking:checked + label::after,
.checkbox-booking:not(:checked) + label::after{
	position: absolute;
	content: '';
	top: -2px;
	right: -2px;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	border-radius: 4px;
	z-index: -2;
	background-color: var(--dark-light);
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
.checkbox-booking:not(:checked) + label::before{
	top: -1px;
	left: -1px;
	width: calc(100% + 2px);
	height: calc(100% + 2px);
}
.checkbox-booking:checked + label::before,
.checkbox-booking:not(:checked) + label::before{
	position: absolute;
	content: '';
	top: -2px;
	left: -2px;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	border-radius: 4px;
	z-index: -2;
	background-image: linear-gradient(138deg, var(--red), var(--color2));
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}



.checkbox-booking:not(:checked) + label::before{
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.checkbox-booking:checked + label::before{
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.checkbox-booking:not(:checked) + label:hover::before{
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}




.checkbox-booking:checked + label::after{
	opacity: 0;
}
.checkbox-booking:checked + label .uil,
.checkbox-booking:not(:checked) + label .uil{
	font-size: 20px;
}
.checkbox-booking:checked + label .text,
.checkbox-booking:not(:checked) + label .text{
	position: relative;
	display: inline-block;
	-webkit-transition: opacity 300ms linear;
	transition: opacity 300ms linear;
}
.checkbox-booking:checked + label .text{
	opacity: 0.6;
}
.checkbox-booking:checked + label .text::after,
.checkbox-booking:not(:checked) + label .text::after{
	position: absolute;
	content: '';
	width: 0;
	left: 0;
	top: 50%;
	margin-top: -1px;
	height: 2px;
	background-image: linear-gradient(138deg, var(--red), var(--color2));
	z-index: 1;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
.checkbox-booking:not(:checked) + label .text::after{
	width: 0;
}
.checkbox-booking:checked + label .text::after{
	width: 100%;
}

.checkbox:checked ~ .section .container .row .col-12 .checkbox-booking:not(:checked) + label,
.checkbox:checked ~ .section .container .row .col-12 .checkbox-booking:checked + label{
	background-color: var(--light);
    color: var(--dark-blue);
}
.checkbox:checked ~ .section .container .row .col-12 .checkbox-booking:checked + label::after,
.checkbox:checked ~ .section .container .row .col-12 .checkbox-booking:not(:checked) + label::after{
	background-color: var(--light);
}




.link-to-page {
	position: fixed;
    top: 30px;
    right: 30px;
    z-index: 20000;
    cursor: pointer;
    width: 50px;
}
.link-to-page img{
	width: 100%;
	height: auto;
	display: block;
}