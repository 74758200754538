:root {
    --yellow: #960f23; /* */
  }
  

  .background-svg-2{ 
    /*
      background-image: url('./slanted-gradient.svg');
      background-position: bottom;
      background-attachment: scroll;
      margin: 0;
      */
  
      margin: 0;
  float: right;
  position: relative;
      height: auto;
    width: 100%;
    background-image: url('./g.svg');
      
      background-attachment: scroll;    
      background-position: top;
      background-size: contain;
   background-repeat: repeat; /**/
  
  
  
      background-color: #f1cbce;
      /* 
      1a1c2a
  191321
      */
  }





  .post {
    display: grid;
    max-width: 1000px;
    margin: 200px auto;
    margin-top: 30px;

    
    
    color: #d1d1d1;
    font-family: Cambria, Cochin, Georgia, 'Times New Roman', Times, serif; /* 'Roboto', sans-serif*/
    font-size: 20px;
  }

  .poster1{
    grid-template-columns: 2fr 16fr 2fr;
    grid-gap: 10px 20px;
        display: grid;
    max-width: 1000px;

  }
  .poster2{
    margin-top: 40px;
    grid-template-columns: 3fr 14fr 3fr;
    grid-gap: 10px 20px;
    display: grid;
    max-width: 1000px;
  }
  




  .post p {
    line-height: 1.8;
    color: #d1d1d1;

  }
  
  .post img {
    max-width: 100%;
  }
  
  .post a {
    text-decoration: none; /*    text-decoration: underline wavy var(--yellow);*/
  }

  
  
  .post h1,
  .post h2 {
    font-size: 80px;
    font-style: bold;
    font-weight: 100;
    margin: 0;

  }

  @media screen and (max-width: 789px) {
    .post h1,
    .post h2 {
      font-size: 10vw;
  
    }
  
  }
  
  .maincaption{
    max-width: 100%;

  }
  
  .post * {
    grid-column: 2 / -2;
  }

  .post .figureright {
    margin: 0;
    margin-right: 30px;
    grid-column: 1 / -1;
    text-align: right;
  }

  .post .figure {
    margin: 0;
    grid-column: 1 / -1;
  }
  
  
  .post figcaption {
    font-size: 12px;
  }

  .figcap{
    font-size: 12px;
  }
  
  .post blockquote {
    grid-column: 2 / -2;
    font-size: 30px;
    margin: 0;
    font-style: italic;
    text-align: center;
  }
  
  .post .tip {
    background: #272626;
    padding: 10px;
    grid-row: span 5;
    align-self: start;
  }
  
  .post .tip-allwayswide{
    width: 100%;
    border-left: 2px solid var(--yellow);
  }

  .lessoncolor{
    color: #bb2037;
  }

  @media screen and (min-width: 1001px) {
    .post .tip-right {
        grid-column: span 1 / -1;
        border-left: 2px solid var(--yellow);
    }
    
    .post .tip-left {
        grid-column: 1 / span 1;
        text-align: right;
        border-right: 2px solid var(--yellow);
    }


  } 

  @media screen and (max-width: 1000px) {
    .post {
        max-width: 100%;
    }
    .post .tip-right, .post .tip-left {
        width: 100%;
        border-left: 2px solid var(--yellow);
      }

    /*.post .tip-left{
        width: 100%;
        border-right: 2px solid var(--yellow);
        text-align: right;
    }*/
    
  } 

  @media screen and (max-width: 1000px) {
    .poster1 {
      grid-template-columns: 1fr 16fr 1fr;
    }
    .poster2 {
    grid-template-columns: 2fr 16fr 2fr;
  }
}

@media screen and (max-width: 450px) {
  .poster2 {
    grid-template-columns: 1fr 18fr 1fr;
  }
}