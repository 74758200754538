.entirecaruselimgdiv{
    height: 70vh;

    width: 1000px;
    margin: auto;

}



.success-imgdiv-settings{
    /*max-width: 1000px;
    height: 60vh; */

    /*height: 60vh; /*60-70vh för dator, 50vh för telefon */
    background-repeat: no-repeat;
    background-size: auto 70vh;    
    background-position: top;
    background-attachment: fixed;

    /*top: -60%;
    /**/
}


.special-case{
    /* top: -50%; */
}

.success-img-settings{
    height: 70vh;
    background-position: bottom;    
}





@media screen and (max-width: 1050px) {
    
    .entirecaruselimgdiv{
        height: 60vh;
        width: 100%;

    }
    

    .success-imgdiv-settings{

        /*height: 60vh; /*60-70vh för dator, 50vh för telefon */
        background-repeat: no-repeat;
        background-size: auto 60vh; 
        justify-content: center;
    }

    .success-img-settings{
        width: 100%;
        height: 60vh;
    
    }

}