.floatleft{float: left;}






.bothdivs{
    margin: 0 auto;
    max-width: 80rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; 
}

.left-landing-div{text-align: left;}

.stortext{
    
    letter-spacing: -0.05em;
    font-weight: 800;
    color: #333;
    font-size: 2.25rem;

}

.rubrik{
    display: block;
    color: rgb(255, 255, 255);
}

.changingtextspan {
    display: block;
    color: rgb(255, 255, 255);
    z-index: 0;
}

.changingtext {
    color: #191321;
}

.smalltext {
    margin-top: 0.75rem;
    font-size: 0.875rem;
    color: rgb(255, 255, 255);
    text-align: left;
} 
  
 /* .landing-icons {display: flex;}
  @media (min-width: 1024px) {
    .landing-icons {
      justify-content: flex-start;
    }
  } */

.landing-icons {
    margin-top: -7px;
}

.contacticons-filter{
    filter: invert(100%) sepia(7%) saturate(990%) hue-rotate(277deg) brightness(118%) contrast(88%);
}
.contacticons-filter:hover{
    /*filter: invert(23%) sepia(92%) saturate(1788%) hue-rotate(330deg) brightness(81%) contrast(103%);*/
    transform: scale(1.3);    
    transition: 0.3s ease all;
    /* filter: grayscale(100%); */

}

.landingpage-contacticons{
    height: 44px; 
    width: 44px;
    margin-right: 2px;
}

.landingpage-contacticons-exception{
    height: 50px; 
    width: 50px;
    margin-right: 2px;
    margin-top: -3px;
}
.landingpage-contacticons-exception2{
    height: 43px; 
    width: 43px;
    margin-right: 2px;
}


.landing-icon {
   margin-right: 1.25rem;
   cursor: pointer;
   margin-top: 2rem;
}
  


.landing-profile-pic {
   width: 50%;
   display: block;
   width: 400px; 
   height: 400px;
   border-radius: 200px;
   filter: grayscale(75%);

   
}
  

@media screen and (min-width: 768px) {   
    .bothdivs{
        justify-content: space-between;
        flex-direction: row;
        margin-top: 0;
}}

@media screen and (max-width: 1024px)  {
    .bothdivs{
        padding-left: 4rem;
        padding-right: 4rem;
}
    
    .left-landing-div{
        text-align: center;
    }
    .smalltext {
        margin-top: 1.25rem;
        font-size: 1rem;
        width: 25rem;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }


    .landing-profile-pic {
        width: 300px; 
        height: 300px;
    }
}

@media screen and (min-width: 1024px) {   
.bothdivs{
    padding-left: 4rem;
    padding-right: 4rem;
}
.stortext{font-size: 3rem;}

.changingtextspan {
    display: inline;
}
.smalltext {
    margin-top: 1.25rem;
    font-size: 1.125rem;
    width: 25rem;
}
}
  
  
@media screen and (min-width: 1280px) {
    .bothdivs{
        padding-left: 2rem;
        padding-right: 2rem;
}
    .smalltext {
      margin-left: 0;
      margin-right: 0;
    }
  }

@media screen and (max-width: 768px) {
    .bothdivs{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .left-landing-div{
        margin-top: 140px;
        z-index: 100;
    }
}

@media screen and (max-width: 900px) {

    .stortext{
        margin-top: -30px;
    }
    .landing-profile-pic {margin-top: 30px; }

} 

@media screen and (max-width: 500px) {

    .changingtextspan {
        /*padding-left: 5%;
        padding-right:  5%;*/
        font-size: 1.8rem;

    }
    .bothdivs{
        padding-left: 0px;
        padding-right:  0px;
    }
    .smalltext {
        margin-top: 1.25rem;
        font-size: 1.125rem;
        width: 100%;
        padding-left: 5%;
        padding-right:  5%;
    }

  .landing-profile-pic {
    
    /*  
        width: 250px; 
        height: 250px;
        */
    } 
}


















.landingpage-bg{
    background-image: url("./imgs/mountain3v4.jpg"); 
    /* 
    bottom color of mountain,blackish color:  
    #191321
    
    
    Red from bot to top 
    Use this -> 
    #d43951
    
    #a32f41   
    #b2404a
    #df5965

    */
    background-size: cover;
    height: 100vh;
    background-position: bottom;
        
}

.aligncontent{align-content: center;}

.picture-div{

    position: absolute;
    left: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  /* filter:grayscale(100%); */

}

.profilepicture{
    background-image: url("./imgs/ovve.jpg");
    width: 100%;
    height: 100%;
    border-radius: 200px;

    position: relative;
    background-position: center;    

}

/* https://www.svgbackgrounds.com/ 


*/